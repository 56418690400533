import * as React from "react"
import { Link } from "gatsby"

import styled from "@emotion/styled"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Container = styled.div`
  width: 100%;
`

const Gallery = styled.ul`
  display: grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 0px;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`

const Thumbnail = styled.div`
  object-fit: fill;
  height: 100%;
  width: 100%;
  transform: scale(1.1);
  transition: transform 2s ease-out;
`

const GalleryItem = styled.li`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  h3 {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translateX(-50%, -50%);
    z-index: 10;
    color: #ffffff;
    text-align: center;
    font-family: "Syne", Helvetica, sans-serif;
    font-weight: 800;
    font-size: 2em;
    font-style: italic;
    opacity: 0;
    transition: all 1s ease-out;
  }
  @media only screen and (max-width: 991px) {
    h3 {
      font-size: 1.25em;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
    background: rgb(191, 84, 123);
    background: -moz-linear-gradient(
      66deg,
      rgba(191, 84, 123, 0.8) 0%,
      rgba(242, 115, 41, 0.8) 100%
    );
    background: -webkit-linear-gradient(
      66deg,
      rgba(191, 84, 123, 0.8) 0%,
      rgba(242, 115, 41, 0.8) 100%
    );
    background: linear-gradient(
      66deg,
      rgba(191, 84, 123, 0.8) 0%,
      rgba(242, 115, 41, 0.8) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bf547b",endColorstr="#f27329",GradientType=1);
  }
  &:hover,
  &:active {
    ${Thumbnail} {
      transform: scale(1.025);
    }
    &:after {
      opacity: 1;
    }
    h3 {
      opacity: 1;
      letter-spacing: 1px;
    }
  }
`

const Portfolio = ({ props }) => {
  return (
    <Container>
      <Gallery>
        {props.map(gallery => (
          <Link to={"/projects/" + gallery.frontmatter.slug}>
            <GalleryItem>
              <Thumbnail>
                <GatsbyImage
                  image={getImage(gallery.frontmatter.thumbnail)}
                  alt={gallery.frontmatter.title}
                />
              </Thumbnail>
              <h3>{gallery.frontmatter.title}</h3>
            </GalleryItem>
          </Link>
        ))}
      </Gallery>
    </Container>
  )
}

export default Portfolio
