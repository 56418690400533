import * as React from "react"

import styled from "@emotion/styled"

const Wrapper = styled.div`
  margin: 0;
  padding-top: 60px;
  color: #0420bf;
  background-color: #ffffff;
  background: linear-gradient(
    66deg,
    rgba(255, 255, 255, 0.95) 0%,
    rgba(255, 255, 255, 0.98) 100%
  );
`

const Container = styled.div`
  margin: auto;
  max-width: 1200px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
  h2 {
    margin: auto;
    font-family: "Syne", Helvetica, sans-serif;
    font-size: 4em;
    font-weight: 800;
    padding-bottom: 20px;
  }
  @media only screen and (max-width: 991px) {
    max-width: 100%;
    padding-bottom: 20px;
    h2 {
      font-size: 3em;
    }
  }
  @media only screen and (max-width: 575px) {
    h2 {
      font-size: 1.25em;
    }
  }
`

const Intro = () => (
  <Wrapper>
    <Container>
      <h2>We thrive when the stakes are high</h2>
      <p>
        Hella built the backbone of Biden for President's ad post-production
        team. With a pandemic raging and the eyes of the world on us, we grew
        from nothing to the largest advertiser in America in a few months.
      </p>
      <p>
        This feat was possible because of our team's unique blend of experience
        in tech and high-end broadcast television. We craft ads and solve
        challenges for brands including Google, Sony, and Peloton and political
        campaigns and committees at all levels.
      </p>
      <p>
        Along the way, we've developed a tech platform to create more
        efficiently and collaboratively, achieving incredible production value
        under intense campaign deadlines.
      </p>
    </Container>
  </Wrapper>
)

export default Intro
