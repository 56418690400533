import * as React from "react"

import styled from "@emotion/styled"

const Wrapper = styled.div`
  margin: 0;
  padding-top: 120px;
  padding-bottom: 120px;
  color: #ffffff;
  background: rgb(3, 37, 140);
  background: -moz-linear-gradient(
    66deg,
    rgba(3, 37, 140, 0.85) 0%,
    rgba(4, 32, 191, 0.95) 100%
  );
  background: -webkit-linear-gradient(
    66deg,
    rgba(3, 37, 140, 0.85) 0%,
    rgba(4, 32, 191, 0.95) 100%
  );
  background: linear-gradient(
    66deg,
    rgba(3, 37, 140, 0.85) 0%,
    rgba(4, 32, 191, 0.95) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#03258c",endColorstr="#0420bf",GradientType=1);
  @media only screen and (max-width: 575px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 4em;
    margin: 0;
    padding-bottom: 40px;
  }
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0;
    padding: 0;
    flex: 50%;
    list-style-type: none;
    text-align: center;
    font-family: "Syne", Helvetica, sans-serif;
    font-size: 1.75em;
    line-height: 2.25em;
  }
  @media only screen and (max-width: 991px) {
    max-width: 100%;
    h2 {
      font-size: 3.5em;
    }
    li {
      flex: 100%;
    }
  }
  @media only screen and (max-width: 575px) {
    max-width: 100%;
    h2 {
      font-size: 1.75em;
    }
    li {
      font-size: 1.25em;
      flex: 100%;
    }
  }
`

const Services = () => (
  <Wrapper>
    <Container>
      <h2>Treat every project like the future of Democracy is at stake</h2>
      <ul>
        <li>Video Post Production</li>
        <li>Digital Experiences</li>
        <li>A/B Testing Pipelines</li>
        <li>Media Management</li>
        <li>Creative Templates</li>
        <li>Backup & Archiving</li>
      </ul>
    </Container>
  </Wrapper>
)

export default Services
