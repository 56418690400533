import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"

import Intro from "../components/sections/Intro"
import PortfolioOne from "../components/sections/PortfolioOne"
import Services from "../components/sections/Services"
import PortfolioTwo from "../components/sections/PortfolioTwo"
import Lab from "../components/sections/Lab"

const IndexPage = ({ data }) => {
  const galleryItems = data.allMarkdownRemark.nodes

  return (
    <Layout>
      <Seo title="Hella" />
      <Intro />
      <PortfolioOne props={galleryItems.slice(0, 4)} />
      <Services />
      <PortfolioTwo props={galleryItems.slice(4)} />
      <Lab />
    </Layout>
  )
}

export const query = graphql`
  query GalleryItems {
    allMarkdownRemark(sort: { fields: frontmatter___order }) {
      nodes {
        frontmatter {
          slug
          order
          title
          client
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: JPG, quality: 90)
            }
          }
          vimeo
        }
      }
    }
  }
`

export default IndexPage
