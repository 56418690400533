import * as React from "react"

import styled from "@emotion/styled"

const Wrapper = styled.div`
  margin: 0;
  padding-top: 120px;
  padding-bottom: 120px;
  color: #0420bf;
  background-color: #ffffff;
  background: linear-gradient(
    66deg,
    rgba(255, 255, 255, 0.95) 0%,
    rgba(255, 255, 255, 0.98) 100%
  );
  @media only screen and (max-width: 575px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    margin: 0;
    text-align: left;
    font-family: "Syne", Helvetica, sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 6em;
    letter-spacing: 0.15em;
  }
  @media only screen and (max-width: 991px) {
    max-width: 100%;
    h2 {
      font-size: 3em;
      line-height: 1em;
    }
  }
  @media only screen and (max-width: 575px) {
    h2 {
      font-size: 2em;
      line-height: 1em;
    }
  }
`

const Form = styled.form`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: auto;
  input,
  button {
    ::placeholder {
      color: #0420bf;
    }
    margin-bottom: 10px;
    padding: 10px;
    outline: none;
    border: 1px solid #0420bf;
    background: none;
    font-family: "Syne", Helvetica, sans-serif;
    font-size: 1em;
    font-weight: 500;
    transition: opacity 0.5s ease;
  }
  input,
  textarea {
    &:focus {
      ::placeholder {
        color: #f27329;
      }
      color: #f27329;
      border: 1px solid #f27329;
    }
  }
  button {
    cursor: pointer;
    background: #0420bf;
    color: #fff;
    &:hover,
    &:active {
      background: #f27329;
      border: 1px solid #fff;
      color: #fff;
    }
  }
`

const Lab = () => (
  <Wrapper>
    <Container>
      <h2>The Lab</h2>
      <p>
        Working in politics, we know effective persuasion starts with the hard
        work of improving peoples' lives. So as we build our proprietary
        software platform, we occasionally release tools for free to the
        community of Democratic creatives. Our goal is to help save you time and
        win votes, and if it also helps you remember our name, that's cool too.
      </p>
      <p>
        We're not harvesting email addresses to sell your information or spam
        you with newsletters hyping our many accomplishments. But if you'd like,
        we'll give you access to The Lab and let you know when something new is
        available.
      </p>
      <Form name="lab" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="lab" />
        <input type="email" name="email" placeholder="Email" />
        <button type="submit">Sign Up</button>
      </Form>
    </Container>
  </Wrapper>
)

export default Lab
